import * as yup from 'yup';

export const machineSchemaData = {
  macAddress: {
    message: 'Input must be 12 characters',
    pattern: /^(?:[a-zA-Z0-9]{2}:){5}[a-zA-Z0-9]{2}$/g,
  },
  serialNumber: {
    message: 'Input must be 15 alphanumeric characters',
    pattern: /^[a-zA-Z0-9]{15}$/g,
  },
} satisfies Record<string, { message: string; pattern: RegExp }>;

const requiredText = 'Required';

export const machineSchema = yup.object().shape({
  bay: yup.string().required(requiredText),
  location: yup.string(),
  macAddress: yup
    .string()
    .trim()
    .length(
      // offset for separator characters
      17,
      machineSchemaData.macAddress.message,
    )
    .matches(
      machineSchemaData.macAddress.pattern,
      machineSchemaData.macAddress.message,
    ),
  name: yup.string().required(requiredText),
  serialNumber: yup
    .string()
    .trim()
    .length(15, machineSchemaData.serialNumber.message)
    .matches(
      machineSchemaData.serialNumber.pattern,
      machineSchemaData.serialNumber.message,
    ),
  status: yup.string().required(requiredText),
  type: yup.string().required(requiredText),
});
