import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  StartJobArgs,
  StartJobResponse,
  StartPrintJobArgs,
} from './start-job.types';

export const startJob = ({ jobId, ...body }: StartJobArgs) => {
  return axios.post<StartJobResponse>(`${coreApiUrl}/jobs/${jobId}/start`, {
    ...body,
  });
};

export const startBambuMachinePrintJob = ({ jobId, ...body }: StartJobArgs) => {
  return axios.post<StartJobResponse>(
    `${coreApiUrl}/jobs/${jobId}/send-gcode-to-printer`,
    {
      ...body,
    },
  );
};

export const startPrintJob = ({
  isBambuMachine,
  ...body
}: StartPrintJobArgs) => {
  return isBambuMachine ? startBambuMachinePrintJob(body) : startJob(body);
};
