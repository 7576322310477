import * as yup from 'yup';
import { machineStatuses, machineTypes } from '../../../../constants/machines';
import { machineSchemaData } from '../../../machine/machine.schema';
import { AddOrEditMachineFormData } from './add-or-edit-machine-modal.types';

export const machineInitialValues: AddOrEditMachineFormData = {
  bay: undefined,
  id: undefined,
  location: 'San Leandro',
  macAddress: undefined,
  name: undefined,
  serialNumber: undefined,
  status: undefined,
  type: undefined,
};

const requiredText = 'Required';

export const addOrEditMachineSchema: yup.SchemaOf<AddOrEditMachineFormData> =
  yup.object().shape({
    bay: yup.string().required(),
    id: yup.number(),
    location: yup.string().required(requiredText),
    macAddress: yup
      .string()
      .trim()
      .length(
        // offset for separator characters
        17,
        machineSchemaData.macAddress.message,
      )
      .matches(
        machineSchemaData.macAddress.pattern,
        machineSchemaData.macAddress.message,
      ),
    name: yup.string().required(requiredText),
    serialNumber: yup
      .string()
      .trim()
      .length(15, machineSchemaData.serialNumber.message)
      .matches(
        machineSchemaData.serialNumber.pattern,
        machineSchemaData.serialNumber.message,
      ),
    status: yup
      .mixed()
      .oneOf(Object.values(machineStatuses))
      .required(requiredText),
    type: yup.mixed().oneOf(Object.values(machineTypes)).required(requiredText),
  });
