import React, { useMemo, useRef } from 'react';
import {
  Button,
  Cell,
  Conditional,
  Stack,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useAsync, useMountedState, useToggle } from 'react-use';
import { FormikProps } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import omit from 'lodash/omit';
import { use100vh } from 'react-div-100vh';
import { useParams } from 'react-router-dom';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { RepairJobModal } from './components/repair-job-modal';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { useConfirmDeleteIssue } from './hooks/use-confirm-delete-issue';
import { MachineTabs } from './machine.constant';
import {
  GeneralSection,
  MachineIssuesTable,
  MachineJobsTable,
  MachineRepairsTable,
  OverviewSection,
} from './sections';
import { Issue } from '../../api/machines/routes/get-issues/get-issues.types';
import { StyledTabListGrid } from './machine.styles';
import { useUpdateMachine } from '../../api/machines/routes';
import { useGetMachineOverviewQuery } from '../../api/machines/routes/get-overview';
import { FormHeader } from './components/form-header';
import { machineSchema } from './machine.schema';
import { useMachineQueriesCache } from './queries-cache';
import { useUserHasAccessLevel } from '../../hooks/use-user-has-access-level';
import { userAccessDeniedMessage } from '../../constants/users';
import { NewMachineIssuesModal } from '../machines/components/new-machine-issues-modal';
import { machineStatuses } from '../../constants/machines';
import { getIsMachineActive } from '../machines/components/add-or-edit-machine-modal/helpers/get-is-machine-active';

type MachinePageTab = typeof MachineTabs[keyof typeof MachineTabs];

export const Machine = () => {
  const params = useParams<{ id: string }>();

  const getIsMounted = useMountedState();

  const { currentValue: selectedTab, updateQueryString: updateSelectedTab } =
    useSyncPropertyWithQueryString<MachinePageTab>(
      'tab',
      MachineTabs.OVERVIEW,
      !getIsMounted() /* Preserve the job ID query for the job details panel only on initial load. */,
    );

  const height = use100vh();

  const formRef = useRef<FormikProps<any>>();
  const [isEditModeActive, setIsEditModeActive] = useToggle(false);

  const { hasLeadAccess } = useUserHasAccessLevel();

  const invalidateAllMachinesCaches = useMachineQueriesCache();

  const currentMachineId = Number(params?.id);

  const onConfirmDeleteIssue = useConfirmDeleteIssue({
    machineId: currentMachineId,
    onSuccess: async () => {
      await onRefreshData();
    },
  });

  const onRefreshData = async () => {
    await invalidateAllMachinesCaches(currentMachineId);
  };

  const { isLoading: isSyncing, onUpdateMachine } = useUpdateMachine({
    onSuccess: async () => {
      setIsEditModeActive(false);
      await onRefreshData();
    },
  });

  const onUpdate = async (values) => {
    const active = getIsMachineActive(values.status);

    const request = {
      ...omit(values, ['id']),
      active,
      editMode: true,
      machineId: values.id,
    };

    await onUpdateMachine(request);
  };

  const { isLoading, response } = useGetMachineOverviewQuery({
    machineId: currentMachineId,
  });

  const currentMachine = response?.machine;
  const { name: machineName } = currentMachine || { name: undefined };

  const onIssueDelete = (record: Issue) => {
    onConfirmDeleteIssue(record.id);
  };

  const [showRepairModal, hideRepairModal] = useModal(() => {
    return (
      <RepairJobModal
        machine={currentMachine}
        onClose={hideRepairModal}
        onJobUpdated={onRefreshData}
      />
    );
  }, [currentMachine]);

  const onCancelEdit = () => {
    formRef.current.resetForm();
    setIsEditModeActive(false);
  };

  const onChangeTab = (tab: MachinePageTab) => {
    updateSelectedTab(tab);

    if (tab !== MachineTabs.OVERVIEW) {
      onCancelEdit();
    }
  };

  const newRepairBtnDisabled = useMemo(() => {
    const validStatuses = [machineStatuses.manual, machineStatuses.offline];

    return !validStatuses.some((status) => {
      return status === currentMachine?.status;
    });
  }, [currentMachine?.status]);

  const initialValues = useMemo(() => {
    return currentMachine || {};
  }, [currentMachine]);

  const [showNewIssueModal, hideNewIssueModal] = useModal(() => {
    return (
      <NewMachineIssuesModal
        machines={[currentMachine]}
        onClose={hideNewIssueModal}
        onSuccess={refreshMachinePage}
      />
    );
  }, [currentMachine]);

  const refreshMachinePage = async () => {
    await invalidateAllMachinesCaches(currentMachineId);
  };

  useAsync(async () => {
    // clear cache when closing the page
    return refreshMachinePage;
  }, []);

  return (
    !!currentMachine &&
    !isLoading && (
      <>
        <MetaData title={`Machine ${machineName}`} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formRef}
          validateOnChange
          validationSchema={machineSchema}
          onSubmit={onUpdate}
        >
          {({ isValid }) => {
            return (
              <Form>
                <Stack
                  height={`${height}px`}
                  overflow="hidden"
                  paddingBottom="4x"
                  rowGap="0"
                  rows="max-content max-content max-content 1fr"
                >
                  <PageHeading title={machineName}>
                    <Conditional
                      condition={selectedTab === MachineTabs.OVERVIEW}
                    >
                      <FormHeader
                        canEdit={currentMachine.status !== 'In progress'}
                        isEditModeActive={isEditModeActive}
                        isSyncing={isSyncing}
                        isValid={isValid}
                        onCancel={onCancelEdit}
                        onEdit={setIsEditModeActive}
                      />
                    </Conditional>
                  </PageHeading>

                  <GeneralSection
                    currentMachine={currentMachine}
                    isEditModeActive={isEditModeActive}
                    isOverviewTabActive={selectedTab === MachineTabs.OVERVIEW}
                  />

                  <Tabs value={selectedTab}>
                    <StyledTabListGrid
                      alignItems="center"
                      columns="1fr max-content"
                    >
                      <Cell>
                        <TabsList hasBorder={false} onValueChange={onChangeTab}>
                          <Tab label="Overview" value={MachineTabs.OVERVIEW} />
                          <Tab
                            label="Print Jobs"
                            value={MachineTabs.PRINT_JOBS}
                          />
                          <Tab label="Issues" value={MachineTabs.ISSUES} />
                          <Tooltip
                            description={
                              !hasLeadAccess && userAccessDeniedMessage
                            }
                          >
                            <Tab
                              disabled={!hasLeadAccess}
                              label="Repairs"
                              value={MachineTabs.REPAIRS}
                            />
                          </Tooltip>
                        </TabsList>
                      </Cell>

                      <Cell>
                        {selectedTab === MachineTabs.ISSUES && (
                          <Button
                            text="New Issue"
                            onClick={showNewIssueModal}
                          />
                        )}

                        {selectedTab === MachineTabs.REPAIRS && (
                          <Tooltip
                            Component={
                              newRepairBtnDisabled && (
                                <Typography
                                  color="t2"
                                  horizontalPadding="1.4rem"
                                  text='Machine status must be "Offline" or "Manual" in order to create a repair.'
                                  verticalPadding=".6rem"
                                />
                              )
                            }
                            position="bottom-end"
                          >
                            <Button
                              disabled={
                                newRepairBtnDisabled ||
                                currentMachine.openIssues.length === 0
                              }
                              text="New Repair Job"
                              onClick={showRepairModal}
                            />
                          </Tooltip>
                        )}
                      </Cell>
                    </StyledTabListGrid>

                    <Stack gap="0" overflow="auto">
                      <TabPanel value={MachineTabs.OVERVIEW}>
                        <OverviewSection
                          currentMachine={currentMachine}
                          isEditModeActive={isEditModeActive}
                        />
                      </TabPanel>

                      <TabPanel value={MachineTabs.PRINT_JOBS}>
                        <MachineJobsTable
                          currentMachine={currentMachine}
                          onRefreshData={onRefreshData}
                        />
                      </TabPanel>

                      <TabPanel value={MachineTabs.ISSUES}>
                        <MachineIssuesTable
                          machineId={currentMachineId}
                          onIssueDelete={onIssueDelete}
                        />
                      </TabPanel>

                      <TabPanel value={MachineTabs.REPAIRS}>
                        <MachineRepairsTable machineId={currentMachineId} />
                      </TabPanel>
                    </Stack>
                  </Tabs>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </>
    )
  );
};
